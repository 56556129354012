import { UPDATE_SELECTED_LANGUAGE, GET_HOMEPAGE_DATA , RESET_HOMEPAGE_DATA, SET_HOMEPAGE_DATA  } from '../constants';

export function setHomePageData(homepage){
    return {
        type: SET_HOMEPAGE_DATA,
        homepage
    }
}

export function getHomePageData(){
    return {
        type: GET_HOMEPAGE_DATA,
    }
}

export function resetHomepageData(){
    return {
        type: RESET_HOMEPAGE_DATA,
    }
}

export function updateSelectedLang(lang){
    return {
        type: UPDATE_SELECTED_LANGUAGE,
        lang
    }
}