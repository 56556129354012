import React from 'react';

class FleckringFoooter extends React.Component {
    render() {
        return (
            <div id="CONTACT" className="sec-footer fleckring-effect">
                <div className="container">
                    {/* <div className="row c-subscribe-apps">
                        <div className="col-12 col-md-6 c-subscribe">
                            <h2 className="box title"></h2>
                            <p className="box info"></p>
                            <div className="box btn subscribe"></div>
                        </div>
                        <div className="col-12 col-md-6 c-apps">
                            <h2 className="box title"></h2>
                            <p className="box info"></p>
                            <div className="c-btns">
                                <div className="box btn app-store"></div>
                                <div className="box btn app-store"></div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row c-contact-social">
                        <div className="col-12 col-md-9 c-contact">
                            {
                                [0, 1, 2].map((item, index) => {
                                    return (
                                        <div className="box contact" key={index}></div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-12 col-md-3 c-social">
                            {
                                [0, 1, 2, 3, 4].map((social, index) => {
                                    return (
                                        <div className="box image" key={index}></div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="row c-sitemap-copyright">
                        <div className="col-12 col-md-8 c-logo-sitemap">
                            <div className="box c-links"></div>
                        </div>
                        <div className="col-12 col-md-4 c-copyright">
                            <div className="box copyright"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FleckringFoooter;