import { RESET_USER_FORM_DATA, UPDATE_IS_DECEASED, UPDATE_COGNITO_DATA , UPDATE_3DS_DATA , UPDATE_USER_DATA , GET_USER_DATA , UPDATE_USER_ENROLL, UPDATE_IRASINCLUSION, UPDATE_SESSION_EXPIRY_ID, RESET_USER_DATA, SET_API_ERROR, UPDATE_TRANSACTION_STATUS, UPDATE_BUSNESSPAYER, SET_3DS_REQUIREMENT, UPDATE_IS_OTHERS } from '../constants';

const paymentDetails = () => ({
    transactionNo : '',
    token: '',
    hasIRASInclusion: null,
    isBusinessPayer: null,
    isDeceased: null,
    isOthers: null,
    redirectToOxiPay: false,
    sessionExtended: false,
    transactionPassed: false,
    transactionType: '',
    sessionId: null,
    Cookie: null,
    Token: null,
    is3DSChallenge: false,
    is3DSNeeded: false,
    isUserSignedIn: false,
    cognitoToken: null,
    userData: {
        name: '',
        userId: '',
        aliasname: '',
        countryCode: '',
        mobile: '',
        email: '',
        registeredAddress: '',
        pincode: '',
        nric: '',
        countryofbirth: '',
        dialect: '',
        nationality: '',
        dateofbirth: '',
        sex: '',
        ckIAgree: true,
        race: '',
        onbehalfof: '',
        streetandpostal: '',
        addressObj: {
            "postalCode": "",
            "blockNumber": "",
            "streetName": "",
            "buildingName": "",
            "country": "",
            "unitNo": "",
            "floorNo": ""
        },
        flow: "",
    },
    apiError: false,
});

export default function userReducer(state = paymentDetails() , action) {

    let userData = action.userData;

    switch(action.type) {
        case UPDATE_USER_DATA:
            return { ...state , ...action.userdata };
        case UPDATE_USER_ENROLL:
            return { ...state , userData };
        case GET_USER_DATA:
            return state;
        case UPDATE_IRASINCLUSION: 
            return { ...state, hasIRASInclusion: action.payload }
        case UPDATE_BUSNESSPAYER: 
            return { ...state, isBusinessPayer: action.payload }
        case UPDATE_IS_DECEASED: 
            return { ...state, isDeceased: action.payload }
        case UPDATE_IS_OTHERS: 
            return { ...state, isOthers: action.payload }
        case UPDATE_SESSION_EXPIRY_ID:
            return {...state, sessionExpiryId: action.id }
        case UPDATE_COGNITO_DATA:
            return {...state, ...action.payload }
        case UPDATE_TRANSACTION_STATUS:{
            return {
                ...state,
                transactionNo: action.status ? '': state.transactionNo, 
                transactionPassed: action.status,
            }
        }
        case UPDATE_3DS_DATA:
                return { ...state , ...action._3dsdata };
        case RESET_USER_DATA:{
            return paymentDetails();
        }
        case RESET_USER_FORM_DATA: {
            return { ...state , ...{ userData: paymentDetails().userData } }
        }
        case SET_API_ERROR:{
            return {...state, apiError: action.apiError}
        }
        default:
            return state;
    }
}