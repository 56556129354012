import React, { lazy, Suspense } from 'react';
import { withTranslation } from "react-i18next";

const ImageLoader = lazy(() => import('../../common/image-loader'));

class BannerHeader extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div className={'c-banner ' + (this.props.noHeading ? 'c-no-heading' : '')}>
                <Suspense fallback={null}>
                    <ImageLoader type="image" src="/images/banner/mosk-header-bg.png"></ImageLoader>
                </Suspense>
                <div className='mask' >&nbsp;</div>
                <div className='c-heading'>
                    <h2 className='title'>{"Zakat Singapore"}</h2>
                    <h4 className='des'>{this.props.description ? this.props.description : t('Fulfil_zakat_online')}</h4>
                </div>
                <div className='btm-bdr-banner'></div>
            </div>
        )
    }
}

export default (withTranslation()(BannerHeader));