import { createSlice } from "@reduxjs/toolkit";
import * as Constants from '../../common/constant';

const getNewYear = () => ({
  year: null,
  noOfDays: 0,
  quantity: 0,
  amount: 0
});

const getInitialState = () => ({
  haid: { years: [getNewYear()], totalAmount: 0 },
  pregnancy: { years: [getNewYear()], totalAmount: 0 },
  illness: { years: [getNewYear()], totalAmount: 0 },
  haidAmount: 0,
  pregnancyAmount: 0,
  illnessAmount: 0,
  totalAmount: 0,
});

/* 
  Slices are new way of writing reducers and is available in @reduxjs/toolkit package. 
  It will remove extra boilerplate code that's is required to setup reducer
  both reducer, and actions are now available in same file 

  @reduxjs/toolkit requires react-redux as peer dependency
*/
const fidyahSlice = createSlice({
  // every reducer has a name and it will be part of action type
  name: 'fidyah',

  // initial state of reducer
  initialState: getInitialState(),

  // all actions goes inside of reducer
  // reducers are meant to reduce previous state to new state
  // each reducer function will accept first params as state and second param as action
  // action object will have payload as well as type i.e "fidyah/reset" [name/reducerName]
  // reducer functions shouldn't return any value 
  reducers: {
    reset: (state) => {
      return getInitialState();
    },

    addHaid: (state) => {
      state.haid.years = [...state.haid.years, getNewYear()];

      state.totalAmount = state.totalAmount - state.haidAmount;

      let totalAmount = 0;
      state.haid.years.forEach((e) => {
        totalAmount += e.amount;
      });

      state.haid.totalAmount = totalAmount;
      state.haidAmount = totalAmount;
      state.totalAmount = state.totalAmount + totalAmount;
    },

    addIllness: (state) => {
      state.illness.years = [...state.illness.years, getNewYear()];

      state.totalAmount = state.totalAmount - state.illnessAmount;

      let totalAmount = 0;
      state.illness.years.forEach((e) => {
        totalAmount += e.amount;
      });

      state.illness.totalAmount = totalAmount;
      state.illnessAmount = totalAmount;
      state.totalAmount = state.totalAmount + totalAmount;
    },

    addPregnancy: (state) => {
      state.pregnancy.years = [...state.pregnancy.years, getNewYear()];

      state.totalAmount = state.totalAmount - state.pregnancyAmount;

      let totalAmount = 0;
      state.pregnancy.years.forEach((e) => {
        totalAmount += e.amount;
      });

      state.pregnancy.totalAmount = totalAmount;
      state.pregnancyAmount = totalAmount;
      state.totalAmount = state.totalAmount + totalAmount;
    },

    updateHaid: (state, action) => {
      const { index, data } = action.payload;
      state.haid.years[index] = data;

      // will remove all items from next item 
      // if(!data.amount){
      //   state.haid.years.splice(index + 1)
      // }

      reduceAmount(state, 'haid');
      calcTotalAmount(state);
    },

    updateIllness: (state, action) => {
      const { index, data } = action.payload;
      state.illness.years[index] = data;

      // if(!data.amount){
      //   state.illness.years.splice(index + 1);
      // }

      reduceAmount(state, 'illness');
      calcTotalAmount(state);
    },

    updatePregnancy: (state, action) => {
      const { index, data } = action.payload;
      state.pregnancy.years[index] = data;

      // if(!data.amount){
      //   state.pregnancy.years.splice(index + 1);
      // }

      reduceAmount(state, 'pregnancy');
      calcTotalAmount(state);
    },

    removeHaid: (state, action) => {
      const years = state.haid.years.filter((value, index) => index !== action.payload ? ({ ...value }): null);

      state.haid.years = years;

      reduceAmount(state, 'haid');
      calcTotalAmount(state);
    },

    removeIllness: (state, action) => {
      const years = state.illness.years.filter((value, index) => index !== action.payload ? ({ ...value }): null);

      state.illness.years = years;

      reduceAmount(state, 'illness');
      calcTotalAmount(state);
    },

    removePregnancy: (state, action) => {
      const years = state.pregnancy.years.filter((value, index) => index !== action.payload ? ({ ...value }): null);

      state.pregnancy.years = years;

      reduceAmount(state, 'pregnancy');
      calcTotalAmount(state);
    },

    updateHaidAmount: (state, action) => {
      const amount = !action.payload ? 0 : action.payload <= Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT ? action.payload : Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT;

      state.haid.years = [getNewYear()];
      state.haid.totalAmount = 0;
      state.haidAmount = amount;
      state.totalAmount = state.illnessAmount + amount + state.pregnancyAmount;
    },

    updateIllnessAmount: (state, action) => {
      const amount = !action.payload ? 0 : action.payload <= Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT ? action.payload : Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT;

      state.illness.years = [getNewYear()];
      state.illness.totalAmount = 0;
      state.illnessAmount = amount;
      state.totalAmount = state.haidAmount + amount + state.pregnancyAmount;
    },

    updatePregnancyAmount: (state, action) => {
      const amount = !action.payload ? 0 : action.payload <= Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT ? action.payload : Constants.MAX_OXPAY_ACCEPTANCE_AMOUNT;

      state.pregnancy.years = [getNewYear()];
      state.pregnancy.totalAmount = 0;
      state.pregnancyAmount = amount;
      state.totalAmount = state.haidAmount + amount + state.illnessAmount;
    },
  }
});

// Helpers
function reduceAmount(state, type = ''){
  if(!type) return;
  
  state[type].totalAmount = state[type].years.reduce((acc, {amount})=>  acc + amount, 0);
  state[type + 'Amount'] = state[type].totalAmount;
}

function calcTotalAmount(state){
  state.totalAmount = state.haidAmount + state.illnessAmount + state.pregnancyAmount;
}

// function sliceArray(state, index = 0, type = ''){
//   if(!type) return;


// }

// will return reducer
export default fidyahSlice.reducer;

// will return actions object
export const fidyahActions = fidyahSlice.actions;