import React, { Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import { APP_FLOW } from '../../common/constant';
import { scrollTo } from '../../common';
import { getAppFlow } from '../utils/app.utils';

const ImageLoader = lazy(() => import('../../common/image-loader'));

class PageConnectionError extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _props: this.props.data,

            /** test - delete */
            _props: { title: '404', heading: 'Page Not Found', subtitle: 'It looks like nothing was found at this location. Try another link or click the button below.', linkText: 'BACK TO HOME', linkURL: `/` }
            /** test */
        }

        this.flowType = getAppFlow();

        this.onClickRedirect = this.onClickRedirect.bind(this);
    }

    onClickRedirect(e) {
        window.location.href = window.location.origin;

        // if (typeof this.state._props.linkURL !== 'undefined') {
        //     this.props.history.push(this.state._props.linkURL);
        // }
    }

    componentDidMount(){
        scrollTo();
    }

    render() {
        return (
            <div className="page-error ">
                <div className='error-body page-not-found' 
                // style={{ backgroundImage: 'url("/images/banner/mosk.png")' }}
                >
                    {/* <div className='mask'></div> */}
                    <div className="c-error">
                        <h3 className='fof'>{this.state._props.title}</h3>
                        <h4>{this.state._props.heading}</h4>
                        <p>{this.state._props.subtitle}</p>
                        <button type="button" className="btn btn-light" onClick={this.onClickRedirect}>{this.state._props.linkText}</button>
                    </div>
                </div>
                {/* <Footer/> */}
            </div>
        )
    }
}

export default withRouter(PageConnectionError);