import { combineReducers } from "redux";
// import zakatReducer from "./zakatReducer";
import homepageReducer from './homepageReducer';
import userReducer from './userReducer';
// import zakatCalcReducer from "./zakatCalcReducer";
// import fidyahReducer from "./fidyah-slice";
import donationWidgetReducer from './donationWidgetSlice';

const rootReducer = combineReducers({
    // zakatReducer,
    // zakatCalcReducer,
    homepageReducer,
    userReducer,
    // fidyahReducer,
    donationWidgetReducer,
})

export default rootReducer;