import React, { lazy } from 'react';
import { connect } from 'react-redux';
import loginPool from '../../aws-pool/login-pool';
import { scrollTo } from '../../common';
// import { Common } from '../../common/common';
// import { APP_FLOW } from '../../common/constant';
// import * as userActions from "../../redux/actions/userAction";
import { getAppFlow } from '../utils/app.utils';
import { withTranslation } from 'react-i18next';

const ImageLoader = lazy(() => import('../../common/image-loader'));


class SessionExpire extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          _props: this.props.data,
          /** test - delete */
          _props: {
            title: 'Session_timeout_title',
            subtitle: "Session_timeout_subtitle",
            linkText: "refresh",
          },
          /** test */
        };

        this.flowType = getAppFlow();
        this.onClickRedirect = this.onClickRedirect.bind(this);
    }

    isReloaded() {
        if(performance.navigation.type == performance.navigation.TYPE_RELOAD) {
            return true;                    
        } else {
            return false;
        }
    }

    componentDidMount() {

        // Route Protection
        if(Boolean(this.props.location?.state?.isSessionExpired) && sessionStorage.getItem("sessionExpiryId")) {
            // const user = loginPool.getCurrentUser();
            // Boolean(user) && user?.signOut();

            sessionStorage.getItem("sessionExpiryId") && clearInterval(sessionStorage.getItem("sessionExpiryId"));

            // Destroy iFrames in case session is expired
            window.document.getElementById("challengeIframe") && window.document.getElementById("challengeIframe").remove();
            window.document.getElementById("ddcIframe") && window.document.getElementById("ddcIframe").remove();

            localStorage.clear();
            sessionStorage.clear();

        } else {
            window.location.href = window.location.origin;
        }

        scrollTo();
    }
    
    onClickRedirect(e) {
        localStorage.removeItem('language'); // set English as default language on Session Refresh
        window.location.href = window.location.origin;
    }

    render() {
        const { t } = this.props;
        return (
          <div className="page-error">
            <div
              className="error-body session"
              // style={{ backgroundImage: 'url("/images/banner/mosk.png")' }}
            >
              {/* <div className="mask"></div> */}
              <div className="c-error">
                <h3>{t(this.state._props.title)}</h3>
                <p>{t(this.state._props.subtitle)}</p>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.onClickRedirect}
                >
                  {t(this.state._props.linkText)}
                </button>
              </div>
            </div>
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      homepage: state.homepageReducer,
      user: state.userReducer
    }
  }

export default connect(mapStateToProps)(withTranslation()(SessionExpire));