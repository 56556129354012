import { createSlice } from "@reduxjs/toolkit";

const getInitialState = () => ({
    cause: {
        donationAmount: 0.00,          // default donation amount
        frequency: 'one time',           // one time | recurrent
        partPayment: '',                // monthly | quarterly | annually
        entity: 'individual',           // individual | corporate
        causes: "",                     // default cause name
        id: '',                         // default campaign name(s)
        minAmount: 5.00,                // minimum donation amount
        maxAmount: 999999.00,           // maximum donation amount
    },
    donationMethod: 'cause',
}) 

const donationWidgetSlice = createSlice({
    name: 'donationWidget',
    initialState: getInitialState(),
    reducers: {
        reset: (state) => getInitialState(),
        updateDonationWidget: (state, action) => {
            state.cause = { ...state.cause, ...action.payload };
        }
    }
})

export default donationWidgetSlice.reducer;
export const donationWidgetActions = donationWidgetSlice.actions;