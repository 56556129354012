import React, { Suspense, lazy } from 'react';
import { Login } from './login';
import { COMPANY_NAME_FULL, COMPANY_NAME_SHORT, scrollTo, SCROLL_NAVIGATION, setNavActiveInactive } from '../../common/'; /* IMG_LOGO, */
import IMG_LOGO from '../../assets/images/header-logo.svg'; /* '../../assets/images/muis-logo.svg' */
import { Common } from '../../common/common';
import { connect } from 'react-redux';
import { APP_FLOW, config, AWS_REGION } from '../../common/constant';
import Pool from '../../aws-pool/login-pool';
import { AccountContext } from '../../aws-pool/Account';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { EnrollService } from '../../jwt/_services/enrollment-service';
import * as userActions from '../../redux/actions/userAction';
import { fidyahActions } from "../../redux/reducers/fidyah-slice";

// Amplify.configure({
//     Auth: {
//         userPoolId: config.AWS_COGNITO_LOGIN_POOL.USER_POOL_ID, //UserPool ID
//         region: AWS_REGION,
//         userPoolWebClientId: config.AWS_COGNITO_LOGIN_POOL.CLIENT_ID, //WebClientId
//         scope: ['phone', 'openid', 'profile', 'email', 'aws.cognito.signin.user.admin'],
//         oauth: {
//             domain: config.OAUTH_DOMAIN,
//             redirectSignIn: String(window.location.origin + window.location.pathname),
//             redirectSignOut: String(window.location.origin + window.location.pathname),
//             responseType: 'token'
//         }
//     }
// });

const ImageLoader = lazy(() => import('../../common/image-loader'));
let navbarToggler = null;
const mobileView = 1024;

class Header extends React.Component {
    // static contextType = PageContext;
    static contextType = AccountContext;
    constructor(props) {
        super(props);
        this.state = {
            headerMenu: [
                { "id": 1, "name": "Home", "naveLink": '/home' },
                { "id": 6, "name": "Contact", "naveLink": '/contact' }
            ],
            filter: [],
            isHeaderMenu: false,
            isLogoVisible: false,
            logoUrl: '',
            isLoginEnable: false,
            isScrolling: false,
            toggleNav: false,
            socialMediaIcon: this.props.homepage.socialMediaIcon,
            appFlow: sessionStorage.getItem('flowType') === APP_FLOW.FIDYAH ? APP_FLOW.FIDYAH : APP_FLOW.ZAKAT,
            profileName: '',
            isPopupOpen: false,
            federatedSignIn: null,
            isUnauthorized: localStorage.getItem('unAuthorizedAcc') !== null ? true : false,
        }

        this.navLinkRef = React.createRef();

        this.scrollSpy = this.scrollSpy.bind(this);
        this.toggleNAV = this.toggleNAV.bind(this);
        this.resetData = this.resetData.bind(this);
        this.navLinkClick = this.navLinkClick.bind(this);
        this.updateProfileName = this.updateProfileName.bind(this);
        this.handlePopupIsOpen = this.handlePopupIsOpen.bind(this);
        this.setAuthorizedSocial = this.setAuthorizedSocial.bind(this);
        this.onResizeHeaderReset = this.onResizeHeaderReset.bind(this);
    }

    setAuthorizedSocial() {
        this.setState({ isUnauthorized: false })
    }

    handlePopupIsOpen(val) {
        this.setState({ isPopupOpen: val });
    }

    toggleNAV() {
        this.setState({ toggleNav: !this.state.toggleNav });
    }

    navLinkClick(e) {
        this.setState({ isScrolling: true });
        let ele = e.target;
        let pathName = ele.innerText.trim().replace(' ', '-').toUpperCase();
        let destination = document.getElementById(pathName);

        if (destination) {
            e.preventDefault();
            SCROLL_NAVIGATION.Referrer = pathName;
            scrollTo(pathName ?? 0);
        } else {
            SCROLL_NAVIGATION.Referrer = pathName;
        }
        this.onResizeHeaderReset();
        setTimeout(() => {
            this.setState({ isScrolling: false });
        }, 300);
    }

    scrollSpy(e) {
        if (!this.state.isScrolling) {
            this.setState({ isScrolling: true });
            setTimeout(() => {
                let ele = null;
                let list = [];

                this.state.headerMenu.forEach(item => {
                    let ele = document.getElementById(item.name.replace(' ', '-').toUpperCase());
                    if (ele) {
                        if ((ele.offsetTop - 280) <= document.body.scrollTop) {
                            list.push(item.name);
                        }
                    }
                });
                this.setState({ isScrolling: false });
                if (list.length > 0) {
                    setNavActiveInactive(list[list.length - 1]);
                }
                list = [];
            }, 500);
        }
    }

    configureHeader() {
        this.setState({
            isLogoVisible: true,
            isHeaderMenu: true,
            headerMenu: [
                { "id": 1, "name": "Home", "naveLink": '/home' },
                { "id": 6, "name": "Contact", "naveLink": '/contact' }
            ],
            logoUrl: '',
            isLoginEnable: true,
        })
    }

    onResizeHeaderReset(isResize) {
        const navbarToggler = document.querySelector('header .navbar-toggler');
        const navbarTogglerHidden = (window.getComputedStyle(navbarToggler)?.display === 'none') ?? navbarToggler.currentStyle?.display === 'none';

        if (window.innerWidth > mobileView) {
            this.setState({ toggleNav: true });
            return;
            //window.addEventListener('resize', this.onResizeHeaderReset);
        } else {
            if (!isResize) {
                this.setState({ toggleNav: false });
                /* if (navbarToggler && navbarTogglerHidden) {
                    this.setState({ toggleNav: true });
                    window.removeEventListener('resize', this.onResizeHeaderReset);
                } else {
                    this.setState({ toggleNav: false });
                    window.addEventListener('resize', this.onResizeHeaderReset);
                } */
            } else {
                if (this.state.isPopupOpen) {
                    this.setState({ toggleNav: true });
                } else {
                    this.setState({ toggleNav: false });
                }
                //this.setState({ toggleNav: !this.state.toggleNav });
                //window.removeEventListener('resize', this.onResizeHeaderReset);
            }
        }
    }

    updateProfileName(name) {
        this.setState({ profileName: name });
    }

    // componentDidMount() {
    //     navbarToggler = document.querySelector('header .navbar-toggler');

    //     const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
    //         switch (event) {
    //             case "signIn":
    //                 console.log(data);
    //                 break;
    //             case "signOut":
    //                 this.setState({ federatedSignIn: null });
    //                 break;
    //             case "customOAuthState":
    //                 console.log(data);
    //                 break;
    //             default:
    //                 break;
    //         }
    //     });

    //     Auth.currentAuthenticatedUser()
    //         .then(currentUser => {
    //             const isGoogleAcc = currentUser?.signInUserSession?.idToken?.payload?.identities[0].providerName === 'Google';
    //             const isFBAcc = currentUser.signInUserSession.idToken.payload.identities[0].providerName === 'Facebook';

    //             const NRIC = localStorage.getItem('socialNRIC');

    //             const NRICattribute = currentUser?.attributes['custom:NRIC'];

    //             if (NRIC !== null && (isGoogleAcc || isFBAcc) && !Boolean(NRICattribute)) {
    //                 this.setState({ federatedSignIn: currentUser }); // set federatedSignIn if isGoogleAcc or isFBAcc
    //                 Auth.updateUserAttributes(currentUser, {
    //                     'custom:BUSINESS_ACC': "No",
    //                     'custom:NRIC': NRIC,
    //                     'email': currentUser?.signInUserSession?.idToken?.payload?.email,
    //                     'name': currentUser?.signInUserSession?.idToken?.payload?.name
    //                 }).then(res => {
    //                     EnrollService.registerCognitoUser({
    //                         phoneCountryCode: '',
    //                         phoneNumber: '',
    //                         uniqueUserId: btoa(NRIC),
    //                     }).then((res) => {
    //                         if (res?.status !== 0) {
    //                             localStorage.removeItem('socialNRIC');
    //                         }
    //                         this.setState({ isLoader: false });
    //                     });
    //                 }).catch((err) => {
    //                     console.log("Something wen't wrong", err);
    //                     localStorage.removeItem('socialNRIC');
    //                     // Auth.signOut().then(() => window.location.reload())
    //                 })
    //             } else if ((isGoogleAcc || isFBAcc) && !Boolean(NRICattribute)) {
    //                 localStorage.setItem('unAuthorizedAcc',true)
    //                 Auth.deleteUser()
    //                 // this.props.popupNotRegistered(true);
    //             } else {
    //                 const countryCode = currentUser?.signInUserSession.idToken?.payload['custom:COUNTRY_CODE'] ?? '';
    //                 let mobileNumber = currentUser?.signInUserSession.idToken?.payload.phone_number ?? '';
    //                 const address = currentUser?.signInUserSession.idToken?.payload.address?.formatted
    //                 const userData = { addressObj: {} };

    //                 EnrollService.getUserDataFromNRIC(btoa(currentUser?.attributes['custom:NRIC'] ?? ''))
    //                     .then((response) => {
    //                         userData.name = response?.primaryName ?? '';
    //                         userData.representativeName = response?.representativeName ?? '';
    //                         userData.countryCode = response?.mobileNumber ? response?.mobileNumber.split(' ')[0] : '';
    //                         userData.mobile = response?.mobileNumber ? response?.mobileNumber.split(' ')[1].match(/.{1,4}/g).join(' ') : '';
    //                         userData.email = response?.emailAddress ?? '';
    //                         userData.nric = response?.companyName ? '' : response?.nricFin;
    //                         userData.uen = response?.companyName ? response.nricFin : '';
    //                         userData.companyName = response?.companyName ?? '';
    //                         userData.dateofbirth = response?.dateOfBirth ?? '';
    //                         userData.registeredAddress = response?.registeredAddress ?? '';
    //                         userData.representativeName = response?.primaryName;
    //                         userData.nationality = response?.nationality ?? '';
    //                         userData.race = response?.race ?? '';
    //                         userData.sex = response?.sex ?? '';
    //                         userData.countryofbirth = response?.countryOfBirth ?? '';
    //                         userData.dialect = response?.dialect ?? '';
    //                         userData.userId = response?.userId ?? '';
    //                         userData.addressObj.postalCode = response?.postalCode ?? "";
    //                         userData.addressObj.blockNumber = response?.blockNumber ?? "";
    //                         userData.addressObj.streetName = response?.streetName ?? "";
    //                         userData.addressObj.buildingName = response?.buildingName ?? "";
    //                         userData.addressObj.country = response?.country ?? "";
    //                         userData.addressObj.unitNo = response?.unitNo ?? "";
    //                         userData.addressObj.floorNo = response?.floorNo ?? "";

    //                         if(response.nationality) {
    //                             userData.flow = response.nationality ? config.FLOW.SINGPASS : config.FLOW.MANUALLY
    //                         }
    //                     })
    //                     .then(() => {

    //                         if (Boolean(userData.name) && Boolean(userData.nric) && Boolean(userData.email)) {

    //                             this.props.dispatch(userActions.updateUserData({ ...this.props.user.userData, ...userData }));
    //                             this.props.updateName && this.props.updateName(userData.name);
    //                         } else {
    //                             userData.name = currentUser?.signInUserSession?.idToken?.payload?.name;
    //                             userData.email = currentUser?.signInUserSession?.idToken?.payload?.email;
    //                             userData.nric = currentUser?.attributes['custom:NRIC'];
    //                             userData.companyName = currentUser?.attributes['custom:COMPANY_NAME'] ?? ''
    //                             userData.uen = currentUser?.attributes['custom:UEN'] ?? '';
    //                             userData.isCorporateAc = currentUser?.attributes['custom:BUSINESS_ACC'];
    //                             userData.countryCode = countryCode;
    //                             userData.mobile = mobileNumber ? mobileNumber.replace(countryCode, '') : '';
    //                             userData.registeredAddress = address;
    //                             userData.representativeName = currentUser?.signInUserSession?.idToken?.payload?.name;

    //                             this.props.dispatch(userActions.updateUserData({ ...this.props.user.userData, ...userData }));
    //                         }

    //                     })
    //             }

    //         })
    //         .catch((err) => console.log("Not signed in", err));

    //     const user = Pool.getCurrentUser();
    //     const { getSession } = this.context;

    //     user && getSession().then(data => {
    //         this.setState({ profileName: Boolean(data.idToken.payload?.name) ? data.idToken.payload?.name : data.idToken.payload?.given_name })
    //     })

    //     const context = this.context;
    //     this.configureHeader();
    //     this.onResizeHeaderReset(false);

    //     const shouldRedirectToHome = this.props.user.hasIRASInclusion === null &&
    //         this.props.user.isBusinessPayer === null &&
    //         this.props.user.isDeceased === null &&
    //         this.props.user.isOthers === null;

    //     const notFidyah = sessionStorage.getItem('flowType') ? sessionStorage.getItem('flowType') === APP_FLOW.ZAKAT ? true : false : true;

    //     this.setState({
    //         socialMediaIcon: context.socialMediaIcon,
    //         appFlow: this.state.appFlow ? (shouldRedirectToHome && notFidyah ? '/' : APP_FLOW.FIDYAH) : APP_FLOW.ZAKAT,
    //     });
    //     /* document.body.addEventListener('scroll', this.scrollSpy); */
    //     window.addEventListener('resize', this.onResizeHeaderReset);
    // }

    componentDidMount() {
        this.configureHeader();
        this.onResizeHeaderReset(false);
        window.addEventListener('resize', this.onResizeHeaderReset);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResizeHeaderReset);
    }

    resetData() {
        // const user = Pool.getCurrentUser();

        if (this.props.user.transactionPassed) {
            sessionStorage.clear('token');
            Common.resetData();
            // !Boolean(user) && Common.resetData();
        }
    }

    render() {

        const pathname = window.location.pathname;

        return (
            <header>
                <div className="container" id='header'>
                  <div className='head-logo'>
                      {
                        this.state.isLogoVisible ?
                            <a href={'/'} onClick={this.resetData} className="navbar-brand" title={COMPANY_NAME_FULL + (COMPANY_NAME_SHORT ? ' - ' + COMPANY_NAME_SHORT : '')}>
                                <Suspense fallback={null}>
                                    <ImageLoader type="image" src={IMG_LOGO}></ImageLoader>
                                </Suspense>
                            </a>
                            :
                            null
                    }
                  </div>
                    <button className="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleNAV}>
                        <span className={this.state.toggleNav ? "navbar-toggler-icon-close" : "navbar-toggler-icon"}></span>
                    </button>
                    <div className='social-icons'>
                        {
                            this.state.socialMediaIcon.map((social, index) => {
                                return (
                                    <a rel="noreferrer"
                                        href={social.socialMediaIconUrl}
                                        target="_blank"
                                        title={social.socialMediaIconName}
                                        key={index}
                                        className={social.socialMediaIconName?.toLowerCase() === 'instagram' ? 'icon-instagram' : ''}>
                                        <Suspense fallback={null}>
                                            <ImageLoader type="image" src={"/images/social/" + social.socialMediaIconName.replace(' ', '').replace().toLowerCase() + "1" + ".svg"}></ImageLoader>
                                        </Suspense>
                                    </a>
                                )
                            })
                        }
                    </div>

                    {/* <nav id="navbarSupportedContent" style={{ 'display': (this.state.toggleNav ? 'flex' : 'none') }}>
                        {
                            pathname.includes('/session-expire') ?
                                null
                                :
                                <div className="c-login">
                                    <Login
                                        isFederatedSignIn={this.state.federatedSignIn}
                                        socialSignOut={() => {
                                            Auth.signOut()
                                                .then(() => {

                                                    window?.FB && window?.FB?.logout(function (response) {
                                                        return true;
                                                    })

                                                    this.props.dispatch(fidyahActions.reset());

                                                }).then(() => window.location.reload())
                                        }}
                                        name={this.state.profileName}
                                        updateName={this.updateProfileName}
                                        handlePopupIsOpen={this.handlePopupIsOpen}
                                        fbCall={() => Auth.federatedSignIn({ provider: 'Facebook' })}
                                        googleCall={() => Auth.federatedSignIn({ provider: 'Google' })}
                                        isUnauthorizedLogin={this.state.isUnauthorized}
                                        setAuthorizedSocial={this.setAuthorizedSocial}
                                    />
                                </div>
                        }
                    </nav> */}
                </div>
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer,
        homepage: state.homepageReducer,
    }
}

export default connect(mapStateToProps)(Header);