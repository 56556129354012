import {
  RESET_USER_FORM_DATA,
  UPDATE_COGNITO_DATA,
  UPDATE_BUSNESSPAYER,
  UPDATE_USER_DATA,
  GET_USER_DATA,
  UPDATE_USER_ENROLL,
  UPDATE_IRASINCLUSION,
  UPDATE_INTERVAL_ID,
  UPDATE_SESSION_EXPIRY_ID,
  RESET_USER_DATA,
  SET_API_ERROR,
  UPDATE_TRANSACTION_STATUS,
  UPDATE_3DS_DATA,
  UPDATE_IS_DECEASED,
  UPDATE_IS_OTHERS,
} from "../constants";

export function updatePaymentData(userdata) {
  return {
    type: UPDATE_USER_DATA,
    userdata,
  };
}

export function update3DSData(_3dsdata) {
  return {
    type: UPDATE_3DS_DATA,
    _3dsdata,
  };
}

export function updateUserData(userData) {
  return {
    type: UPDATE_USER_ENROLL,
    userData,
  };
}

export function getPaymentData() {
  return {
    type: GET_USER_DATA,
  };
}

export function updateIRASInclusion(flag) {
  return {
    type: UPDATE_IRASINCLUSION,
    payload: flag,
  };
}

export function updateBusinessUser(flag) {
  return {
    type: UPDATE_BUSNESSPAYER,
    payload: flag,
  };
}

export function updateIsDeceased(flag) {
  return {
    type: UPDATE_IS_DECEASED,
    payload: flag,
  };
}

export function updateIsOthers(flag) {
  return {
    type: UPDATE_IS_OTHERS,
    payload: flag,
  };
}

export function updateCognitoData(cognito) {
  return {
    type: UPDATE_COGNITO_DATA,
    payload: cognito,
  };
}

export function updateIntervalId(id) {
  return {
    type: UPDATE_INTERVAL_ID,
    id,
  };
}

export function updateSessionExpiryId(id) {
  return {
    type: UPDATE_SESSION_EXPIRY_ID,
    id,
  };
}

export function updateFidyahSessionExpiryId(id) {
  return {
    type: UPDATE_SESSION_EXPIRY_ID,
    id,
  };
}

export function updateTransactionStatus(status) {
  return {
    type: UPDATE_TRANSACTION_STATUS,
    status,
  };
}

export function resetUserData() {
  return {
    type: RESET_USER_DATA,
  };
}

export function resetUserFormData() {
  return {
    type: RESET_USER_FORM_DATA,
  };
}

export function setApiError(apiError) {
  return {
    type: SET_API_ERROR,
    apiError: apiError,
  };
}
