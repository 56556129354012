import React, { Suspense, lazy } from "react";
import ReactDOM from 'react-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { withRouter } from "react-router-dom";
import { COMPANY_NAME_FULL } from "../../common";
import { connect } from 'react-redux';
import { config } from '../../common/constant';
import Loader from '../ILHAM/loader';
import { withTranslation } from "react-i18next";
import { profile } from "../../jwt/_services/profile-service";

const ImageLoader = lazy(() => import('../../common/image-loader'));

const ExternalLoader = () => {
    return (
        <>{ReactDOM.createPortal(<div className='sec-loader'><Loader /><div className='backdrop'></div></div>, document.getElementById('secLoader'))}</>
    )
}

class PopupNRICSignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialValues: {
                nric: ''
            },
            signupError: {
                hasError: false,
                message: ''
            },
            isLoader: false,
            isContinueDisabled: true
        }
    }

    clearErrors = () => {
        this.state.signupError.hasError && this.setState({
            signupError: {
                hasError: false,
                message: ''
            },
        });
    }

    handleOnKeyUp = () => {
        this.clearErrors();
    }

    componentDidMount() {
        window.addEventListener("keyup", this.handleOnKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleOnKeyUp);
    }

    render() {

        const { t } = this.props;

        return (
            <>
                <div className="popup login nric-only">
                    <div className="body">
                        <button className="btn btn-light btn-close" onClick={() => this.props.changeForm('CLOSE_ALL')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                <path id="close_1_" data-name="close (1)" d="M8.874,7.636l5.841-5.841A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.795L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0" transform="translate(0 -0.136)" fill="#fff" />
                            </svg>
                        </button>
                        <div className="row">
                            <div className="col-md-12 logo">
                                <Suspense fallback={null}>
                                    <ImageLoader type="image" src="/images/muis/muis-2.png" alt={COMPANY_NAME_FULL}></ImageLoader>
                                </Suspense>
                                <span>Zakat Singapore</span>
                            </div>
                        </div>
                        {this.state.signupError.hasError &&
                            <div className="row c-notify warning">
                                <div className="col-md-12">
                                    {this.state.signupError.message === "PreSignUp failed with error NRIC already exists."
                                        ? <div dangerouslySetInnerHTML={{ __html: `Sign up Failed. NRIC already exists.<br/>Please call us at +65 6359 1199 for more details.` }} />
                                        : this.state.signupError.message === "PreSignUp failed with error UEN already exists." ? <div dangerouslySetInnerHTML={{ __html: `Sign up Failed. UEN already exists.<br/>Please call us at +65 6359 1199 for more details.` }} /> : this.state.signupError.message}
                                </div>
                            </div>
                        }

                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.initialValues}
                            validateOnMount={false}
                            validateOnChange
                            validateOnBlur
                            validate={(values) => {
                                const errors = {};

                                // NRIC validation
                                if (values.nric.trim().length <= 0) {
                                    errors.nric = t('Enrol_enter_nric_fin');
                                    this.setState({ isContinueDisabled: true })
                                } else if (config.REGEX.NRIC.test(values.nric.trim()) === false) {
                                    errors.nric = t('ValidateForm_validNric');
                                    this.setState({ isContinueDisabled: true })
                                } else {
                                    this.setState({ isContinueDisabled: false })
                                }

                                return errors;
                            }}

                            onSubmit={async (fields, { setFieldError }) => {

                                this.setState({ isLoader: true });
                                profile.checkNRICUnique({ "nric": fields.nric.trim() })
                                .then(res => {
                                    if(res?.data?.status === 1) {
                                        localStorage.setItem('socialNRIC', fields.nric.trim());
                                        this.props.socialSignInRedirect();
                                    } else {
                                        setFieldError('nric', t('nirc_exists'));
                                    }

                                    this.setState({ isLoader: false });
                                })

                            }}

                            render={({ errors, values, isValid, isSubmitting, touched, handleBlur, setFieldValue }) => (
                                <Form
                                    id="formCCPayment"
                                    className="form"
                                    autoComplete="off"
                                >
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label htmlFor="nric" className="cust-label">{t('Enrol_nric_fin')} <span className="star">*</span></label>
                                            <Field
                                                type="text"
                                                name="nric"
                                                id="nric"
                                                placeholder={t('Enrol_enter_nric_fin')}
                                                className={'form-control ' + (errors.nric && touched.nric ? ' is-invalid' : '')}
                                                onChange={(ev) => {
                                                    setFieldValue("nric", String(ev.target.value).toUpperCase())
                                                }}
                                                onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                                            />
                                            <ErrorMessage name="nric" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-dark btn-login"
                                        disabled={this.state.isContinueDisabled}
                                    >
                                        {t('Continue_label')}
                                    </button>
                                </Form>
                            )}
                        />
                        <div className="c-register">
                            <button type='button' onClick={() => { this.props.changeForm('SIGN_UP') }} className="btn btn-light btn-register" > {t('back_to_signup')}</button>
                        </div>
                    </div >
                </div >
                <div className="popup-backdrop"></div>
                {this.state.isLoader && <ExternalLoader />}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer
    }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(PopupNRICSignUp)));