//import { createContext } from 'react';

// export const PaymentContext = {

//     zakat: {
//         savings: { name: 'Savings', type: null, amount: 0.00, accounts: [] }, // amounts Ex : amounts : [{ id : 0 , lowestAmt : 0.00 , interest : 0.00 }]
//         gold: { name: 'Gold', type: null, amount: 0.00, accounts: [] },
//         insurance: { name: 'Insurance', type: null, amount: 0.00, accounts: [] },
//         business: { name: 'Business', type: null, amount: 0.00, accounts: [] },
//         zakatfitrah: { name: 'Zakat Fitrah', rate: null, people: 0, amount: 0.00, accounts: [] }, // conditionally rendered
//         shares: { name: 'Shares', type: null, amount: 0.00, accounts: [] },
//         totalAmount: 0,
//         taxDeductionText: '',

//         default: {
//             savings: { name: 'Savings', type: null, amount: 0.00, accounts: [] }, // amounts Ex : amounts : [{ id : 0 , lowestAmt : 0.00 , interest : 0.00 }]
//             gold: { name: 'Gold', type: null, amount: 0.00, accounts: [] },
//             insurance: { name: 'Insurance', type: null, amount: 0.00, accounts: [] },
//             business: { name: 'Business', type: null, amount: 0.00, accounts: [] },
//             zakatfitrah: { name: 'Zakat Fitrah', rate: null, people: 0, amount: 0.00, accounts: [] }, // conditionally rendered
//             shares: { name: 'Shares', type: null, amount: 0.00, accounts: [] },
//         }
//     },
//     todaysNisabValue: '',

//     paymentDetails: {
//         transactionNo: '',
//         cardTokenId: '',
//         cardId: '',
//         fingerprint: '',
//         isUserRegistered: '',
//         isPatronMember: ''
//     },

//     donationMethod: 'harta',
// }

//import { createContext } from 'react';

// Dummy Data from NGS
export const PaymentContext = {
    secondaryUserData : {
    },
    cause: {
        donationAmount: 0.00,
        frequency: 'one time',
        partPayment: '',
        entity: 'individual',
        causes: "The Gallery's Vision",         // selected cause name(s)
        id: '',                             // selected campaign id(s)
        default: {
            donationAmount: 0.00,          // default donation amount
            frequency: 'one time',           // one time | recurrent
            partPayment: '',                // monthly | quarterly | annually
            entity: 'individual',           // individual | corporate
            causes: "The Gallery's Vision",     // default cause name
            id: '',                         // default campaign name(s)
            minAmount: 5.00,                // minimum donation amount
            maxAmount: 999999.00,           // maximum donation amount
        }
    },
    campaign: {
        donationAmount: 0.00,
        frequency: 'one time',
        partPayment: '',
        entity: 'individual',
        name: "Campaign Name",              // selected cause name(s)
        id: '',                             // selected campaign id(s)
        causes: ["The Gallery's Vision"],
        default: {
            donationAmount: 0.00,          // default donation amount
            frequency: 'one time',           // one time | recurrent
            partPayment: '',                // monthly | quarterly | annually
            entity: 'individual',           // individual | corporate
            name: 'Campaign Name',          // default campaign name
            id: '',                         // default campaign name(s)
            minAmount: 5.00,                // minimum donation amount
            maxAmount: 999999.00,           // maximum donation amount
            causes: ["The Gallery's Vision"],   // default cause(s)
        }
    },
    patron: {
        donationAmount: 0.00,
        frequency: 'one time',
        partPayment: '',
        entity: 'individual',
        name: "Patron Name",                // selected cause name(s)
        id: 0,                             // selected campaign id(s)
        causes: "The Gallery's Vision",
        default: {
            donationAmount: 0.00,        // default donation amount
            frequency: 'one time',           // one time | recurrent
            partPayment: '',                // monthly | quarterly | annually
            entity: 'individual',           // individual | corporate
            name: 'Patron Name',            // default cause name
            id: 0,                         // default campaign name(s)
            minAmount: 2000.00,             // minimum donation amount
            maxAmount: 999999.00,           // maximum donation amount
            causes: "The Gallery's Vision",   // default cause name(s)
        }
    },
    adoptartwork: {
        donationAmount: 50.00,
        frequency: 'one time',
        partPayment: '',
        entity: 'individual',
        artworkName: "Artwork Name",         // selected cause name(s)
        programName: "",
        id: 0,                             // selected campaign id(s)
        privilegeid: 0,
        causes: ["The Gallery's Vision"],
        default: {
            donationAmount: 50.00,        // default donation amount
            frequency: 'one time',           // one time | recurrent
            partPayment: '',                // monthly | quarterly | annually
            entity: 'individual',           // individual | corporate
            name: 'Artwork Name',            // default cause name
            id: 0,                         // default campaign name(s)
            minAmount: 50.00,             // minimum donation amount
            maxAmount: 200000.00,           // maximum donation amount
            causes: ["The Gallery's Vision"],   // default cause name(s)
        }
    },
    paymentDetails : {
        transactionNo : '',
        cardTokenId : '',
        cardId : '',
        fingerprint : '',
        isUserRegistered : '',
        isPatronMember: ''
    },
    donationMethod: 'cause',                // cause | campaign | adoptartwork | patron
    navLinkRef: null,
    referrer: '',
    isScrolling: false,
    isLearnMore: false,
    setNavActiveInactive: function (exception) {
        let children = this.navLinkRef?.current ? this.navLinkRef.current.childNodes : null;
        if (children) {
            children.forEach(item => {
                if (!exception) {
                    item.classList.remove('active');
                } else {
                    if (item.innerText.trim().toUpperCase() === exception.replace('-', ' ').toUpperCase()) {
                        item.classList.add('active');
                    } else {
                        item.classList.remove('active');
                    }
                }
            });
        }
    },
    resetValues: function (method) {
        let donationMethod = this[method];
        let objDefault = donationMethod['default'];
        for (let key in objDefault) {
            donationMethod[key] = objDefault[key];
        }
    },
    scrollTo: function (param, top) {
        let offset = true;
        let ele = document.getElementById(param);
        if (ele) {
            if(document.body.offsetWidth <= 1024){offset = false;}
            document.body.scrollTop = param.toUpperCase() === 'HOME' ? 0 : (typeof top === 'number') ? top : (ele.offsetTop - (offset ? this.navLinkRef.current.offsetHeight : 100));
            this.setNavActiveInactive(param.toUpperCase());

        } else if (typeof param === 'number') {
            document.body.scrollTop = param;
        } else {
            document.body.scrollTop = 0;
            if (param) {
                this.setNavActiveInactive(param);
            } else {
                this.setNavActiveInactive();
            }
        }
        this.referrer = '';
    },
}