import React from 'react';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { config } from '../../src/common/constant';
import { configureStore } from '../redux/configureStore';

export const Common = {
    getReadableDateFormat,
    navigateToLink,
    standardDateFormat,
    disableFutureDt,
    copyURL,
    isValidField,
    mobileFormatter,
    formatNumber,
    dateFormatterLeftBlock,
    muisDateFormatter,
    scrollToTop,
    resetData,
    generateToken,
    isValidUrl,
    intlNumberFormat,
    percentage,
    validateCreditCard,
    isMobile,
};

function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function validateCreditCard(creditCardNumber) {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(creditCardNumber)) return false;

    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0;
    let nDigit = 0; 
    let bEven = false;
    let strippedField = creditCardNumber.replace(/\D/g, "");

    for (let n = strippedField.length - 1; n >= 0; n--) {
        let cDigit = strippedField.charAt(n);
        nDigit = parseInt(cDigit, 10);

        if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;
    }

    return (nCheck % 10) == 0;
}


function percentage(percent, total) {
    return Number(((percent / 100) * total).toFixed(2))
}

function intlNumberFormat(value, fracCount = 2) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' , minimumFractionDigits: fracCount }).format(value)
  }

function isValidUrl(string) {
    try { return Boolean(new URL(string)); }
    catch(e){ return false; }
}

function generateToken(){
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substring(2);

    return (head + tail);
}

function resetData() {
    const { persistor } = configureStore();

    persistor.pause();
    persistor.purge();
}

function scrollToTop(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
}

function isValidField(value) {
    if (value === "" || value === null || value === 'null' || value === 'NA' || value === " " || value === undefined || value === "NULL" || value === []
    ) {
        return false
    } else {
        return true
    }
}

function getReadableDateFormat(value) {
    var given = moment("2021-06-28 17:00:00", "YYYY-MM-DD hh:mm:ss");
    var current = moment();
    var diff = moment.duration(given.diff(current));
}

function formatNumber(e) {

    const { currentTarget } = e;

    if (!/[0-9]/.test(e.clipboardData.getData('text/plain'))) {
        e.preventDefault();
    } else {
        var val = e.clipboardData.getData('text/plain').replace(/[^\d]/g, '').replace(/(.{4})/g, '$1 ').trim();
        setTimeout(() => {
            currentTarget.value = '';
            currentTarget.value = val;
            currentTarget.preventDefault();
        }, 10);
        // 2223003122003222
    }
}

function navigateToLink(navlink) {
    window.open(navlink, '_blank');
}

function standardDateFormat(cell) {
    if (!cell) { return ""; }
    if (typeof cell === 'string') {
        return `${moment(cell).format('D MMM YYYY')}`;
    }
}

function disableFutureDt(current) {
    const today = moment();
    return current.isBefore(today)
}

function copyURL() {
    const elem = document.createElement('input');
    elem.value = document.URL;
    // elem.hidden = true;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
}

function mobileFormatter(cell, row) {
    if (Common.isValidField(cell)) {
        return <CurrencyFormat displayType={'text'} format="+## #### ######" mask="" value={cell} />
    } else {
        return '-'; //<span className="d-block text-center no-value">-</span>
    }
}

function dateFormatterLeftBlock(cell) {
    if (!Common.isValidField(cell)) { return ""; }

    let dateTime;
    if (typeof cell === 'string') {
        dateTime = cell.trim().split(' ');
    }

    if (typeof cell === 'string' && dateTime.length > 1) {
        return `${moment(new Date(dateTime[0].replace(/-/g, "/"))).format(config.SAFARI_FORMAT.DATE)} | ${moment((dateTime[1] + ' ' + dateTime[2]), [config.SAFARI_FORMAT.TIME]).format(config.SAFARI_FORMAT.TIME)}`;// th, rd removed from all over places coming after Date number.
    } else {
        return `${moment(cell).format(config.SAFARI_FORMAT.DATE)}` + "  |  " + `${moment(cell).format(config.SAFARI_FORMAT.TIME)}`; // th, rd removed from all over places coming after Date number.
    }
    // return `${moment.utc(cell).tz("Asia/Singapore").format('Do MMM YYYY')}` + "  |  " + `${moment.utc(cell).tz("Asia/Singapore").format('h:mm A')}`;
}

function muisDateFormatter(cell) {
    if (!Common.isValidField(cell)) { return ""; }

    let dateTime;
    if (typeof cell === 'string') {
        dateTime = cell.trim().split(' ');
    }

    if (typeof cell === 'string' && dateTime.length > 1) {
        return `${moment(new Date(dateTime[0].replace(/-/g, "/"))).add(355, 'day').format(config.SAFARI_FORMAT.DATE)}`;// th, rd removed from all over places coming after Date number.
    } else {
        return `${moment(cell).format(config.SAFARI_FORMAT.DATE)}`; 
    }
    
}