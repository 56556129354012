import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import {createLogger} from "redux-logger";

// Redux Persist Code
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from "redux-devtools-extension";
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { config, REDUX_ENCRYPTION_KEY } from "../common/constant";

// Will Allow Redux Dev Tools to bind with Redux
const composeEnhancers = composeWithDevTools({ 
    trace: true, 
    traceLimit: 25 
}); 

// persistConfig file contains for the keyname and the storage type we want to use
const persistConfig = {
    key: 'root',
    storage,
}

// Bind current reducers with the persistReducers
const persistedReducer = persistReducer(persistConfig, rootReducer);

const persistedReducerEncrypted = persistReducer({
      key: 'root',
      storage,
      transforms: [
        encryptTransform({
          secretKey: REDUX_ENCRYPTION_KEY,
          onError: function (error) {
            // Handle the error.
          },
        }),
      ],
    },
    rootReducer
);

// With Persist
export function configureStore() {
    let store = config.TYPE === "PROD" ? createStore(persistedReducerEncrypted) : createStore(persistedReducerEncrypted, undefined, composeEnhancers())
    let persistor = persistStore(store)
    return { store, persistor }
}