export const SET_HOMEPAGE_DATA = "SET_HOMEPAGE_DATA";
export const GET_HOMEPAGE_DATA = "GET_HOMEPAGE_DATA";
export const RESET_HOMEPAGE_DATA = "HOMEPAGE/RESET_HOMEPAGE_DATA";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_ENROLL = "UPDATE_USER_ENROLL";
export const GET_USER_DATA = "GET_USER_DATA";
export const RESET_USER_DATA = "USER/RESET_USER_DATA";
export const RESET_USER_FORM_DATA = "RESET_USER_FORM_DATA";
export const UPDATE_TRANSACTION_STATUS = "USER/UPDATE_TRANSACTION_STATUS";
export const UPDATE_SOURCE_OF_INCOME = "UPDATE_SOURCE_OF_INCOME";
export const UPDATE_SOURCE_OF_INCOME_CALC ="UPDATE_SOURCE_OF_INCOME_CALC";

export const UPDATE_ZAKAT_DATA = "UPDATE_ZAKAT_DATA";
export const GET_ZAKAT_DATA = "GET_ZAKAT_DATA";
export const UPDATE_COGNITO_DATA = "UPDATE_COGNITO_DATA";
export const UPDATE_IS_DECEASED = "UPDATE_IS_DECEASED";
export const UPDATE_IS_OTHERS = "UPDATE_IS_OTHERS";

// BUSINESS
export const UPDATE_BUSINESS_DATA = "ZAKAT_CALC/BUSINESS/UPDATE_BUSINESS_DATA";

export const UPDATE_IRASINCLUSION = "UPDATE_IRASINCLUSION";
export const UPDATE_BUSNESSPAYER = "UPDATE_BUSNESSPAYER";

export const ZAKAT_PURGE = "ZAKAT/PURGE";
export const ZAKAT_CALC_PURGE = "ZAKAT_CALC/PURGE";
export const PURGE_FITRAH = "PURGE_FITRAH";

export const UPDATE_TOTAL = "UPDATE_TOTAL";
export const UPDATE_TOTAL_CALC = "UPDATE_TOTAL_CALC";

export const GET_ZAKAT_CALC_DATA = "GET_ZAKAT_CALC_DATA";
export const UPDATE_ZAKAT_CALC_DATA = "UPDATE_ZAKAT_CALC_DATA";

export const UPDATE_FITRAH_DATA = "UPDATE_FITRAH_DATA";
export const RESET_FITRAH_DATA = "RESET_FITRAH_DATA";
export const UPDATE_ZAKAT_FITRAH_DATA = "ZAKAT/UPDATE_ZAKAT_FITRAH_DATA";
export const UPDATE_ZAKAT_CALC_FITRAH_DATA = "UPDATE_ZAKAT_CALC_FITRAH_DATA";

export const UPDATE_SAVINGS_DATA = "UPDATE_SAVINGS_DATA";
export const UPDATE_GOLD_DATA = "UPDATE_GOLD_DATA";
export const UPDATE_INSURANCE_DATA = "UPDATE_INSURANCE_DATA";
export const UPDATE_SHARES_DATA = "UPDATE_SHARES_DATA";
export const UPDATE_SHARES_DATA_TAXABLE = "UPDATE_SHARES_DATA_TAXABLE";

export const UPDATE_SAVINGS_SOURCE_TYPE = "UPDATE_SAVINGS_SOURCE_TYPE";
export const UPDATE_GOLD_SOURCE_TYPE = "UPDATE_GOLD_SOURCE_TYPE";
export const UPDATE_INSURANCE_SOURCE_TYPE = "UPDATE_INSURANCE_SOURCE_TYPE";
export const UPDATE_BUSINESS_SOURCE_TYPE = "UPDATE_BUSINESS_SOURCE_TYPE";
export const UPDATE_SHARES_SOURCE_TYPE = "UPDATE_SHARES_SOURCE_TYPE";

export const RESET_GOLD_DATA = "RESET_GOLD_DATA";

export const UPDATE_GOLD_DATA_IN_USE = "UPDATE_GOLD_DATA_IN_USE";
export const UPDATE_GOLD_DATA_NOT_IN_USE = "UPDATE_GOLD_DATA_NOT_IN_USE";
export const UPDATE_SELECTED_LANGUAGE = "UPDATE_SELECTED_LANGUAGE";

export const UPDATE_INTERVAL_ID = "UPDATE_INTERVAL_ID";
export const UPDATE_SESSION_EXPIRY_ID = "UPDATE_SESSION_EXPIRY_ID";
export const UPDATE_FIDYAH_SESSION_EXPIRY_ID = "UPDATE_FIDYAH_SESSION_EXPIRY_ID";
export const UPDATE_3DS_DATA = "UPDATE_3DS_DATA";

export const ADD_NEW_SAVINGS_DATA = "ADD_NEW_SAVINGS_DATA";
export const ADD_NEW_SHARES_DATA = "ADD_NEW_SHARES_DATA";
export const ADD_NEW_INSURANCE_DATA = "ADD_NEW_INSURANCE_DATA";
export const REINITIALIZE_SHARES_DATA = "ZAKAT_CALC/REINITIALIZE_SHARES_DATA";

export const REMOVE_SAVINGS_DATA = "REMOVE_SAVINGS_DATA";
export const REMOVE_SHARES_DATA = "REMOVE_SHARES_DATA";
export const REMOVE_INSURANCE_DATA = "REMOVE_INSURANCE_DATA";

export const REINITIALIZE_SAVINGS_DATA = "REINITIALIZE_SAVINGS_DATA";
export const REINITIALIZE_INSURANCE_DATA = "REINITIALIZE_INSURANCE_DATA";

export const UPDATE_NISAB_VALUE = "UPDATE_NISAB_VALUE";

export const SET_API_ERROR = "USER/SET_API_ERROR";

export const SUBMITTING_ZAKAT_CALC_FORM = "SUBMITTING_ZAKAT_CALC_FORM";