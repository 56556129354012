import React, { lazy, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { PaymentContext } from './data/context';
import { authContext } from './data/authContext';
import PageContext, { ContextProvider } from "./context";
import { homePageService } from "./jwt/_services/home-page-service";
import './assets/css/style.css';
// import AuthenticateUser from "./aws-pool/authenticate-user";
import Header from './section/header/header';
import Footer from './section/footer/footer';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from "amazon-cognito-identity-js";
import { decryptData, EMAIL_AWS_COGNITO, getSecret, PASSWORD_AWS_COGNITO, USER_NAME_AWS_COGNITO } from "../src/common";
import authAwsPool from "../src/aws-pool/auth-aws";
import ConnectionError from './views/error-pages/connection-error';
import PageConnectionError from './views/error-pages/page-connection-error';
import SessionExpire from './views/error-pages/session-expire';
import Loader from './views/ILHAM/loader';
import { Account } from './aws-pool/Account';
import { connect } from 'react-redux';
import * as homepage from './redux/actions/homepageAction';
import * as user from './redux/actions/userAction';
import { sign, verify } from "web-token";
import moment from "moment";
import { Common } from "./common/common";
import { APP_FLOW, config, sessionTimeUnit, AWS_REGION, paths } from "./common/constant";
// import DonationReceiptOM from "./views/receipts/receipt-our-masgid";
// import { fidyahActions } from "./redux/reducers/fidyah-slice";
// import ChangePassword from "./section/blank-page/blank-page";
// import Pool from './aws-pool/login-pool'
// import { Amplify } from 'aws-amplify';
import { donationWidgetActions } from "./redux/reducers/donationWidgetSlice";

// Amplify.configure({
//   Auth: {
//     region: AWS_REGION,
//     userPoolId: config.AWS_COGNITO_LOGIN_POOL.USER_POOL_ID, //UserPool ID
//     userPoolWebClientId: config.AWS_COGNITO_LOGIN_POOL.CLIENT_ID //WebClientId
//   }
// });

const ILHAM = lazy(() => import('./views/ILHAM'));

// const UserProfile = lazy(() => import('./views/profile/profile'));
const ReceiptDonation = lazy(() => import('./views/receipts/receipt'));
// const RegistrationForTaxDeduction = lazy(() => import('./views/register/tax-deduction'));
const EnrollIndividual = lazy(() => import('./views/payments/components/enroll-individual'))
const SingpassUpdate = lazy(() => import('./views/payments/components/singpass-update'))
const SingpassLogin = lazy(() => import('./views/payments/components/singpass-login'));
const Enrollment = lazy(() => import('./views/payments/charity/enrollment'));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: window.location.host,
      isDataLoaded: false,
      settingsData: [],
      isOnline: window.navigator.onLine,
      isAuthorised: false,
    }

    this.token = this.token.bind(this);
    this.getHomePageData = this.getHomePageData.bind(this);
    this.updateConnection = this.updateConnection.bind(this);
    this.getAuthorizerDetails = this.getAuthorizerDetails.bind(this);
    this.getAuthorisedHomePage = this.getAuthorisedHomePage.bind(this);
  }

  getHomePageData() {
    homePageService.getHomePageSettings()
    .then(response => {
      if (response) {
        this.props.dispatch(homepage.setHomePageData(response));
        response.PaymentContext = PaymentContext;
        this.setState({
          settingsData: response ?? [],
          isDataLoaded: true
        });
      } else {
        this.setState({
          isOnline: false,
          isDataLoading: true,
          settingsData: []
        })
      }
    });
  }

  generateSessionToken(userId, SECRET_KEY) {
    const TextToEncode = userId; // Will be replaced with userId from WordPress

    const signedDataAsText = sign(
      TextToEncode,
      SECRET_KEY,
      moment().add(sessionTimeUnit.time, sessionTimeUnit.unit) // Add 1 Hour to current date & time for the token
    ).toString('hex');

    console.log("Token Created! Will Expire at : ", moment().add(sessionTimeUnit.time, sessionTimeUnit.unit).format('DD/MM/YYYY hh:mm:ss A'));

    // const sessionExpiryTimeoutId = setTimeout(()=>{

    // }, moment().add(sessionTime, sessionTimeUnit).milliseconds);

    return signedDataAsText;
  }

  renewSessionToken(userId, SECRET_KEY) {
    const TextToEncode = userId; // Will be replaced with userId from WordPress

    const signedDataAsText = sign(
      TextToEncode,
      SECRET_KEY,
      moment().add(sessionTimeUnit.time, sessionTimeUnit.unit) // Add 10 Minutes to current date & time for the token
    ).toString('hex');

    console.log("Token Renewed! Will Expire at : ", moment().add(sessionTimeUnit.time, sessionTimeUnit.unit).format('DD/MM/YYYY hh:mm:ss A'));
    
    return signedDataAsText;
  }

  token(secretKey) {
    // userId : <String_Alphanumeric>
    // Harta or Fidyah <String>

    let token = sessionStorage.getItem("token");

    const isSessionExpirePath =
      window.location.pathname.includes("/session-expire");
    const isReceiptPath = window.location.pathname.includes("/receipt");

    if (isSessionExpirePath || isReceiptPath) {
      return;
    }

    // Check if Token Exists
    if (token === null) {
      const userId = Common.generateToken();

      // const user = Pool.getCurrentUser();

      // This reset data incorrectly on component mount as token is initially null (open link in new tab)
      // token is maintained in session storage
      // if (user) {
      //     this.props.dispatch(zakat.resetFitrahData());
      //     this.props.dispatch(zakat.resetZakatData());
      //     this.props.dispatch(zakatCalc.resetZakatCalcData());
      //     this.props.dispatch(fidyahActions.reset());
      // } else {
      //     Common.resetData();
      // }

      const newToken = this.generateSessionToken(userId, secretKey);

      sessionStorage.setItem("token", newToken);

      const info = verify(Buffer.from(newToken, "hex"), secretKey);

      this.checkSession(info.expires);
    } else {
      let currentToken = sessionStorage.getItem("token");

      const info = verify(Buffer.from(currentToken, "hex"), secretKey);

      console.log(
        "Token Exists! Expires at : ",
        moment(info.expires).format("DD/MM/YYYY hh:mm:ss A")
      );

      this.checkSession(info.expires);

      // Check if token is valid and has expired or not

      if (info.isValid && info.expires < Date.now()) {
        // /**
        //  * If expired need to check if user was redirected from OxPay by the time token expired
        //  * and if time was already extended previosuly for the given Token
        //  */

        if (
          this.props.user.redirectToOxiPay == true &&
          this.props.user.sessionExtended != true
        ) {
          console.log("OxPay Redirection Detected! Renewing Token....");

          let originalString = info.data.toString();

          const updatedToken = this.renewSessionToken(
            originalString,
            config.SECRET_KEY
          );

          this.props.dispatch(
            user.updatePaymentData({
              ...this.props.user,
              ...{ sessionExtended: true },
            })
          );
          sessionStorage.setItem("token", updatedToken);

          const info = verify(Buffer.from(updatedToken, "hex"), secretKey);

          this.checkSession(info.expires);
        } else {
          /**
           * In case Token was expired and user was not redirected from OxPay then
           * clear the token, reset Redux Store and send user back to homepage
           * */

          console.log("Token Expired Resetting....");

          sessionStorage.getItem("sessionExpiryId") &&
            clearInterval(sessionStorage.getItem("sessionExpiryId"));

          // sessionStorage.clear('token');

          Common.resetData();

          const targetURL = "/session-expire";

          this.props.history.push(targetURL);
        }
      }
    }
  }

  async getAuthorizerDetails() {
    let poolId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerAccessKey}`).then((key) => decryptData(key));
    let clientId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerClientId}`).then((key) => decryptData(key));
    
    return {
      UserPoolId: poolId,
      ClientId: clientId
    };

  }

  // isLoadHomePage allow to load homepage data boolean flag
  getAuthorisedHomePage(isLoadHomePage, poolDetails) {
    const user = new CognitoUser({
      Username: USER_NAME_AWS_COGNITO,
      Pool: new CognitoUserPool(poolDetails),
    });

    const authDetails = new AuthenticationDetails({
      Username: USER_NAME_AWS_COGNITO,
      Password: PASSWORD_AWS_COGNITO,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // localStorage.setItem("accessToken", data.)AccessToken, IdToken, RefreshToken, TokenType=Bearer
        authContext.AccessToken = data.accessToken.jwtToken
        authContext.IdToken = data.idToken.jwtToken
        authContext.RefreshToken = data.refreshToken.token
        // authContext.TokenType = data.refreshToken.token 
        if (isLoadHomePage) {
          this.getHomePageData();
          // this.token();
        } else {
          this.setState({
            isDataLoaded: true
          })
        }
        this.setState({
          isAuthorised: true
        })
      },
      onFailure: (error) => {
        console.error("onFailure: ", error);
      },
      newPasswordRequired: (response) => {
        const userAttributes = {
          'email': EMAIL_AWS_COGNITO
        }
        console.log("newPasswordRequired: ", userAttributes, response);
        user.completeNewPasswordChallenge(PASSWORD_AWS_COGNITO, userAttributes, {
          onSuccess: (result) => {
            console.log("NEW PASSWORD COMPLETED: New Password Set");
            // console.log(result);
          },
          onFailure: (err) => {
            // console.log(err);
          }
        })
      },
    })
  }

  updateConnection(status) {
    this.setState({
      isOnline: status
    })
  }

  checkSession = (date) => {
    sessionStorage.getItem("sessionExpiryId") && clearInterval(sessionStorage.getItem("sessionExpiryId"));    

    const intrvalId = setInterval(() => {
      if(moment(date).isBefore(moment())){
        const targetURL = "/session-expire";

            this.props.history.push(targetURL, { isSessionExpired: true });

        clearInterval(sessionStorage.getItem("sessionExpiryId"));
      }
    }, 1000);

    sessionStorage.setItem("sessionExpiryId", intrvalId);
  }

  //
  componentDidMount() {
    this.getAuthorizerDetails()
    .then(poolDetails => {
      if (window.location.pathname.indexOf('/receipt') !== -1 || window.location.pathname.indexOf('/register') !== -1) {
        this.getAuthorisedHomePage(false, poolDetails)
        // this.setState({ isDataLoaded: true });
      } else {
        this.getAuthorisedHomePage(true, poolDetails)
        // this.getHomePageData();
      }
      
      window.addEventListener('offline', () => this.updateConnection(false))
      window.addEventListener('online', () => this.updateConnection(true))
      console.log(this.state.isOnline, "online");

      if (!sessionStorage.getItem("sessionExpiryId") && !Boolean(window.location.pathname.includes('session-expire'))) {

        getSecret(`/${config.TYPE.toLowerCase()}${paths.SessionKey}`)
        .then(key => decryptData(key))
        .then((secretKey) => this.token(secretKey))

        const date = moment().add(sessionTimeUnit.time, sessionTimeUnit.unit); 

        const intervalId = setInterval(() => {
          if(date.isBefore(moment())){
            const targetURL = "/session-expire";
    
            this.props.history.push(targetURL);
            clearInterval(sessionStorage.getItem("sessionExpiryId"));
          }
        }, 1000);
        
        sessionStorage.setItem("sessionExpiryId", intervalId)
      } else {
        // if sessionExpiryId exists
        getSecret(`/${config.TYPE.toLowerCase()}${paths.SessionKey}`)
        .then(res => decryptData(res))
        .then((secretKey) => this.token(secretKey))
      }
    })
  }

  componentDidUpdate() {
    if((window.location.pathname === '/') && 
      this.props.user.transactionPassed){
        this.props.dispatch(user.updateTransactionStatus(false))
        this.props.dispatch(user.resetUserData())
        this.props.dispatch(donationWidgetActions.reset())
    }
  }

  // componentDidMount() {

  //   this.getAuthorizerDetails()
  //   .then(poolDetails => {

  //     if (window.location.pathname.indexOf('/receipt') !== -1 || window.location.pathname.indexOf('/register') !== -1) {
  //       this.getAuthorisedHomePage(false, poolDetails)
  //     } else {
  //       this.getAuthorisedHomePage(true, poolDetails)
  //     }

  //     window.addEventListener('offline', () => this.updateConnection(false))
  //     window.addEventListener('online', () => this.updateConnection(true))

  //     if (!sessionStorage.getItem("sessionExpiryId") && !Boolean(window.location.pathname.includes('session-expire'))) {

  //       getSecret(`/${config.TYPE.toLowerCase()}${paths.SessionKey}`)
  //       .then(key => decryptData(key))
  //       .then((secretKey) => this.token(secretKey))

  //       const date = moment().add(sessionTimeUnit.time, sessionTimeUnit.unit); 

  //       const intervalId = setInterval(() => {
  //         if(date.isBefore(moment())){
  //           const targetURL = 
  //             window.location.pathname.includes("/fidyah") || sessionStorage.getItem("flowType") === APP_FLOW.FIDYAH 
  //               ? "/fidyah/session-expire"
  //               : "/session-expire";
    
  //           this.props.history.push(targetURL);
  //           clearInterval(sessionStorage.getItem("sessionExpiryId"));
  //         }
  //       }, 1000);
        
  //       sessionStorage.setItem("sessionExpiryId", intervalId)

  //     }

  //   })

  // }

  // componentDidUpdate() {
  //   if((window.location.pathname === '/' || window.location.pathname === '/fidyah' || window.location.pathname === "/calculator") && 
  //     this.props.user.transactionPassed){
  //       this.props.dispatch(fidyahActions.reset());
  //       this.props.dispatch(zakat.resetZakatData());  
  //       this.props.dispatch(zakatCalc.resetZakatCalcData());
  //       this.props.dispatch(user.updateTransactionStatus(false))

  //       const poolUser = Pool.getCurrentUser();

  //       if(!Boolean(poolUser)){
  //         this.props.dispatch(user.resetUserData());
  //       }
  //   }

  //   if(sessionStorage.getItem("flowType") === APP_FLOW.FIDYAH && 
  //     !window.location.pathname.includes("/fidyah")) {
  //       this.props.dispatch(fidyahActions.reset());

  //       const poolUser = Pool.getCurrentUser();

  //       if(!Boolean(poolUser)) {
  //         this.props.dispatch(user.resetUserData());
  //       }

  //     sessionStorage.setItem("flowType", APP_FLOW.ZAKAT);
  //   } else if(sessionStorage.getItem("flowType") === APP_FLOW.ZAKAT && 
  //     window.location.pathname.includes("/fidyah")) {
  //       this.props.dispatch(zakatCalc.resetZakatCalcData());
  //       this.props.dispatch(zakat.resetZakatData());

  //       const poolUser = Pool.getCurrentUser();

  //       if(!Boolean(poolUser)){
  //         this.props.dispatch(user.resetUserData());
  //       }

  //     sessionStorage.setItem("flowType", APP_FLOW.FIDYAH);
  //   }

  //   getSecret(`/${config.TYPE.toLowerCase()}${paths.SessionKey}`)
  //   .then(res => decryptData(res))
  //   .then((secretKey) => this.token(secretKey))
  // }

  render() {
    return (
      <>
        {(this.state.isOnline && !this.props.user.apiError) ?
          <>
            {
              this.state.isDataLoaded && this.state.isAuthorised ?
                window.location.pathname.indexOf('/receipt') !== -1 || window.location.pathname.indexOf('/register') !== -1 ?
                <>
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        <Route exact path="/receipt/donation" component={ReceiptDonation} />
                      </Switch>
                    </Suspense>
                  </>
                  :

                    <Account>
                      <ContextProvider value={this.state.settingsData}>
                        <>
                          <Header />
                            <Suspense fallback={<Loader />}>
                              <Switch>
                                <Route exact path="/session-expire" component={SessionExpire} />
                                {/* <Route exact path="/aws-pool" component={AuthenticateUser} /> */}
                                {/* <Route exact path="/singpass/confirmation" component={AuthenticateUser} /> */}
                                <Route exact path="/enrollment/enroll-individual" component={EnrollIndividual} />
                                <Route exact path="/enrollment/singpass-update" component={SingpassUpdate} />
                                <Route exact path="/enrollment/singpass" component={SingpassLogin} />
                                <Route path="/enrollment/:id?" component={Enrollment} />
                                {/* <Route exact path="/profile" component={UserProfile} /> */}
                                {/* <Route exact path="/reset-password" component={ChangePassword} /> */}
                                <Route exact path="/" component={ILHAM} />
                                <Route path="*" component={PageConnectionError} />
                              </Switch>
                              <Footer />
                            </Suspense>
                        </>
                      </ContextProvider>
                    </Account>

                : <Loader />
            }
          </> :
          <Suspense fallback={null}>
            <ConnectionError {...this.props}></ConnectionError>
          </Suspense>
        }
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    homepage: state.homepageReducer,
    user: state.userReducer
  }
}

export default withRouter(connect(mapStateToProps)(App))