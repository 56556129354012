import { GET_HOMEPAGE_DATA , RESET_HOMEPAGE_DATA, SET_HOMEPAGE_DATA, UPDATE_SELECTED_LANGUAGE  } from '../constants';
// Storing the user selected language in local storage to maintain selected language when redux-persist is purged after a transaction
// Remove language from local storage on logout
const lang = localStorage.getItem('language')

const homepage = {
    lang: lang ? lang : "en"
}

export default function homepageReducer(state = homepage , action) {

    switch(action.type) {
        case SET_HOMEPAGE_DATA:
            const homepageData = action.homepage;
            return { ...state, ...homepageData };
        case GET_HOMEPAGE_DATA:
            return state;
        case UPDATE_SELECTED_LANGUAGE:
            localStorage.setItem('language', action.lang)
            return { ...state, ...{ lang: action.lang } }
        case RESET_HOMEPAGE_DATA:
            return null;
        default:
            return state;
    }

}