import { connect } from 'react-redux';

import { config } from "../../common/constant";
import React, { Suspense, lazy } from 'react';
import FleckringFooter from './fleckring-footer';
import PageContext from '../../context';
import FooterLogo from '../../assets/images/footer.svg';
import moment from "moment";
import { withTranslation } from 'react-i18next';
const ImageLoader = lazy(() => import('../../common/image-loader'));

class FooterSection extends React.Component {
    static contextType = PageContext;
    constructor(props) {
        super(props);
        this.state = {
            contactDetails: null,
            newsLetterSettings: null,
            settings: [],
            gallaryBlock: null,
            gallarySetting: null,
            contactUsBlock: null,
            socialMediaBlock: null,
            socialMediaIcon: null,
            year: moment().format('YYYY')
        };
    }

    componentDidMount() {

        this.setState({
            contactDetails: this.props.homepage?.conactDetails ?? { contactNumber: '', contactEmail: '', contactAddress: '' },
            socialMediaIcon: this.props.homepage?.socialMediaIcon ?? [],
        });

    }

    render() {

        const { t } = this.props;

        return (
            <>
                {
                    this.state.contactDetails && this.state.socialMediaIcon ?
                        <div id="CONTACT" className="sec-footer">
                            <div className="container">
                                <div className="row c-contact-social">
                                    <div className=" col-sm-9 c-contact col-xs-9">
                                        {this.state.contactDetails.contactNumber ?
                                            <a rel="noreferrer" href={"tel:" + (this.state.contactDetails.contactNumber)} target="_blank" title="Phone Number">
                                                <div className='c-img'>
                                                    <Suspense fallback={null}>
                                                        <ImageLoader type="image" src="/images/contact/tel.svg"></ImageLoader>
                                                    </Suspense>
                                                </div>
                                                <span>{this.state.contactDetails.contactNumber}</span>
                                            </a> : null
                                        }
                                        {this.state.contactDetails.contactEmail ?
                                            <a rel="noreferrer" href={"mailto:" + (this.state.contactDetails.contactEmail)} target="_blank" title="Email Address">
                                                <div className='c-img'>
                                                    <Suspense fallback={null}>
                                                        <ImageLoader type="image" src="/images/contact/email.svg"></ImageLoader>
                                                    </Suspense>
                                                </div>
                                                <span>{this.state.contactDetails.contactEmail}</span>
                                            </a> : null
                                        }
                                        {true ?
                                            <a rel="noreferrer" href={"http://www.ilham.sg/"} target="_blank" title="Website">
                                                <div className='c-img'>
                                                    <Suspense fallback={null}>
                                                        <ImageLoader type="image" src="/images/contact/website.svg"></ImageLoader>
                                                    </Suspense>
                                                </div>
                                                <span>www.ilham.sg</span>
                                            </a> : null
                                        }
                                    </div>
                                    <div className=" col-sm-3 c-social col-xs-3">
                                        {
                                            this.state.socialMediaIcon.map((social, index) => {
                                                return (
                                                    <a rel="noreferrer"
                                                        href={social.socialMediaIconUrl}
                                                        target="_blank"
                                                        title={social.socialMediaIconName}
                                                        key={index}
                                                        className={social.socialMediaIconName?.toLowerCase() === 'instagram' ? 'icon-instagram' : ''}>
                                                        <Suspense fallback={null}>
                                                            <ImageLoader type="image" src={"/images/social/" + social.socialMediaIconName.replace(' ', '').toLowerCase() + ".svg"}></ImageLoader>
                                                        </Suspense>
                                                    </a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="row c-sitemap-copyright">
                                    <div className="col-xs-12 col-sm-8  c-logo-sitemap">
                                        <Suspense >
                                            <ImageLoader type="image" src={FooterLogo}></ImageLoader>
                                        </Suspense>
                                        <div className="c-links">
                                            <a href={config.PRIVACY_POLICY} target="_blank" rel="noreferrer">{t('Footer_privacy')}</a>
                                            <a href={config.TERMS_OF_USE} target="_blank" rel="noreferrer">{t('Footer_terms')}</a>
                                            {/* <a href='#'>{t('Footer_privacy')}</a>
                                                <a href='#'>{t('Footer_terms')}</a> */}
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-4  c-copyright">
                                        <span>© {this.state.year} ILHAM. All rights reserved.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <FleckringFooter></FleckringFooter>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    return { homepage: state.homepageReducer }
}

export default connect(mapStateToProps)(withTranslation()(FooterSection));
