import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { config, paths } from './common/constant';
// import AuthProvider from './service/auth-provider/authProvider';
// import { history } from './jwt/_helpers';
import i18next from 'i18next'	
import { initReactI18next } from 'react-i18next'	
import HttpApi from 'i18next-http-backend'	
import LanguageDetector from 'i18next-browser-languagedetector'
// Redux Required Files
import { Provider } from 'react-redux'
import { configureStore } from './redux/configureStore';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';

const { store , persistor } = configureStore();

const options = {
  order: ['path', 'cookie', 'htmlTag'],
  caches: ['cookie'],
}

const autoDetectOption = {
// order and from where user language should be detected
order: ['navigator', 'htmlTag', 'path', 'subdomain'],

// keys or params to lookup language from
lookupQuerystring: 'lng',
lookupCookie: 'i18next',
lookupLocalStorage: 'i18nextLng',
lookupSessionStorage: 'i18nextLng',
lookupFromPathIndex: 0,
lookupFromSubdomainIndex: 0,

// cache user language on
caches: ['localStorage', 'cookie'],
excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

// optional expire and domain for set cookie
cookieMinutes: 10,
cookieDomain: 'myDomain',

// optional htmlTag with lang attribute, the default is:
htmlTag: document.documentElement,

// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
cookieOptions: { path: '/', sameSite: 'strict' }
}

i18next
.use(HttpApi)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  supportedLngs: ['en', 'ms'],
  fallbackLng: 'en',
  debug: false,
  // Options for language detector
  detection: localStorage.getItem('isSelectedLanguage') === null ? autoDetectOption : options,
  backend: {
    loadPath: '/assets/locales/{{lng}}/translation.json',
  },
})

document.addEventListener('DOMContentLoaded', async () => {
      ReactDOM.render(
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </PersistGate>
            </Provider>,
      document.getElementById('root')
      );
});
