import CryptoJS from 'crypto-js';
import { AWS_REGION, AWS_SECRET_KEY_ID, AWS_SECRET_ACCESS_KEY, CRYPTO_ACCESS_KEY } from '../common/constant';
import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { config, paths } from '../common/constant';

export function encryptData(data) {
    const encryptedData = CryptoJS.AES.encrypt(data, CRYPTO_ACCESS_KEY).toString();
    return encryptedData;
}

export function decryptData(data) {
    const decryptedData = CryptoJS.AES.decrypt(data, CRYPTO_ACCESS_KEY).toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

AWS.config.update({
    region: AWS_REGION,
    accessKeyId: decryptData(AWS_SECRET_KEY_ID),
    secretAccessKey: decryptData(AWS_SECRET_ACCESS_KEY),
});

export async function getLoginPool() {

    let poolId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerAccessKey}`).then(res => decryptData(res));
    let clientId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerClientId}`).then(res => decryptData(res));
    
    const Pool =  new CognitoUserPool({
      UserPoolId: poolId,
      ClientId: clientId
    });

    return Pool;
};

const ssm = new AWS.SSM();

export const getSecret = async(name) => {
    const res = await ssm
    .getParameter({
        Name: name,
        WithDecryption: false
    })
    .promise();
    return res.Parameter.Value;
}

export const IMG_LOGO = '/logo-big.png';
export const COMPANY_NAME_FULL = 'ILHAM';
export const COMPANY_NAME_SHORT = '';
export const COMPANY_MICRO_SITE = 'https://www.zakat.sg/';
export const CURRENCY_SYMBOL = '$';
export const USER_NAME_AWS_COGNITO = 'muis';
export const PASSWORD_AWS_COGNITO = 'Admin@123';
// export const EMAIL_AWS_COGNITO = 'mayank.kumar@ideaqu.com'; //DEV Email
export const EMAIL_AWS_COGNITO = 'muis@givepls.com'; // UAT email

export const SCROLL_NAVIGATION = { 'ElementNavLink': null, 'Referrer': '' };

export const CREDIT_CARD = {
    monthOptions: [
        { "value": "01", "label": "01" },
        { "value": "02", "label": "02" },
        { "value": "03", "label": "03" },
        { "value": "04", "label": "04" },
        { "value": "05", "label": "05" },
        { "value": "06", "label": "06" },
        { "value": "07", "label": "07" },
        { "value": "08", "label": "08" },
        { "value": "09", "label": "09" },
        { "value": "10", "label": "10" },
        { "value": "11", "label": "11" },
        { "value": "12", "label": "12" }
    ],
    cardImages: {
        AmericanExpress: '/images/cards/american-express-195x125.png',
        DefaultCard: '/images/cards/default-195x125.png',
        DinersClub: '/images/cards/diners-club-195x125.png',
        JCB: '/images/cards/jcb-195x125.png',
        MasterCard: '/images/cards/master-card-195x125.png',
        UnionPay: '/images/cards/union-pay-195x125.png',
        Visa: '/images/cards/visa-195x125.png',
    },

    getCardExpiryYearList() {
        var yearList = [];
        var year = (new Date()).getFullYear();
        for (let i = 0; i < 21; i++) {
            const obj = {
                value: year + i,
                label: year + i
            }
            yearList.push(obj)
        }
        return yearList;
    },
}

export const PAYMENT_OPTIONS = {
    cardImages: {
        /* googlepay: '/images/cards/googlepay.svg',
        applepay: '/images/cards/applepay.svg',        
        alipay: '/images/cards/alipay.svg',
        grabpay: '/images/cards/grab-pay.svg', */
        googlepay: '/images/cards/googlepay.png',
        applepay: '/images/cards/applepay.png',        
        alipay: '/images/cards/alipay.png',
        grabpay: '/images/cards/grab-pay.png',
        paynow: '/images/cards/pay-now.png',
        cash: '/images/cards/cash-195x125.png',
        cheque: '/images/cards/cheque-195x125.png',
        nets: '/images/cards/nets-195x125.png',
    }
}

export const GET_PARENT = (e, nodename, classname) => {
    let ele = e;
    //console.log(ele, nodename, classname);
    try {
        do {
            //console.log('node', ele.nodeName);
            if (nodename && ele.nodeName === nodename || classname && ele.classList.contains(classname)) {
                return ele;
            } else {
                ele = ele.parentNode;
            }
        } while (ele.parentNode)
    } catch (ex) {
        //throw ex;
    }
}

export const TEXT_TRIM = (text, characters) => {
    try {
        if (text.length > characters) {
            return ((text.substr(0, characters)).trim() + '...');
        } else {
            return text;
        }
    } catch (ex) {
        return text;
    }
}

export const commaSeperatedAmount = (sign, value) => {
    if (value === undefined || value === null)
        return ''
    let val = value.toString();
    let str = [];
    let decimalval = val.split('.')

    if (decimalval.length === 2) {
        val = decimalval[0];
    }

    if (val && val.length > 3) {
        for (let ind = val.length - 1; ind >= 0; ind--) {
            if (!(ind % 3) && ind !== 0) {
                str.push(val[val.length - 1 - ind]);
                str.push(',');
            } else {
                str.push(val[val.length - 1 - ind]);
            }
        }
        val = str.join('');
    }

    if (decimalval.length === 2) {
        return sign + val + '.' + decimalval[1];
    } else {
        return sign + val;
    }
}

/** used for react-select control */
export let selectedStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: state.isSelected ? '#142552' : state.isDisabled ? '#ccc' : '',
            backgroundColor: state.isSelected ? 'transparent' : 'white',
            cursor: state.isFocused ? 'pointer' : 'default',
            '&:hover': { backgroundColor: state.data?.disabled ? '' : '#14255220', color: state.isDisabled ? '' : '#192931' }
        }
    },
}

export const setNavActiveInactive = (exception) => {
    let children = SCROLL_NAVIGATION.ElementNavLink ? SCROLL_NAVIGATION.ElementNavLink.childNodes : null;
    if (children) {
        children.forEach(item => {
            if (!exception) {
                item.classList.remove('active');
            } else {
                if (item.innerText.trim().toUpperCase() === exception.replace('-', ' ').toUpperCase()) {
                    item.classList.add('active');
                } else {
                    item.classList.remove('active');
                }
            }
        });
    }
}

export const scrollTo = (param, top) => {
    let offset = true;
    let ele = document.getElementById(param);
    if (ele) {
        if (document.body.offsetWidth <= 1024) { offset = false; }
        document.body.scrollTop = param.toUpperCase() === 'HOME' ? 0 : (typeof top === 'number') ? top : (ele.offsetTop - (offset ? SCROLL_NAVIGATION.ElementNavLink.offsetHeight : 100));
        setNavActiveInactive(param.toUpperCase());

    } else if (typeof param === 'number') {
        document.body.scrollTop = param;
    } else {
        document.body.scrollTop = 0;
        if (param) {
            setNavActiveInactive(param);
        } else {
            setNavActiveInactive();
        }
    }
    SCROLL_NAVIGATION.Referrer = '';
}
