import React, { createContext } from "react";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./login-pool";
import { decryptData, getSecret } from "../common";
import { config, paths } from "../common/constant";

(async () => {
  
  let poolId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerAccessKey}`).then(res => decryptData(res));
  let clientId = await getSecret(`/${config.TYPE.toLowerCase()}${paths.AuthorizerClientId}`).then(res => decryptData(res));
  
  const Pool =  new CognitoUserPool({
    UserPoolId: poolId,
    ClientId: clientId
  });
  
})();

// This has been created so that user validates his credentials again for particular use cases(like change password)
const AccountContext = createContext();
// This has been created to get the current session of the user.
const Account = (props) => {

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });
            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              headers: {
                Authorization: token,
              },
              ...session,
              ...attributes,
            });
          }
        });
      } else {
        reject();
      }
    });
  // basic authentication feature calling CognitoUser with username,password and pool
  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log("newPasswordRequired:", data);
          resolve(data);
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      window.location.reload();
    }
  };

  const forgotPassword = async (Username) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (data) => {
          reject(data);
        },

      });
    });
  };

  const changePassword = async (Username, oldPassword, newPassword) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      user.changePassword(oldPassword, newPassword, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },
      });
    });
  };

  return (
    <AccountContext.Provider
      value={{
        forgotPassword,
        changePassword,
        authenticate,
        getSession,
        logout,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
