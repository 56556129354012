import React from 'react';
import { withTranslation } from "react-i18next";

class PopUpLogout extends React.Component {
    
    render() {
        const {t} = this.props;
        return (
            <>
                <div className="popup">
                    <div className="popup-logout">
                        <button className="btn btn-light btn-close" onClick={this.props.closeLogoutPopup}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                                <path id="close_1_" data-name="close (1)" d="M8.874,7.636l5.841-5.841A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.795L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0" transform="translate(0 -0.136)" fill="#fff" />
                            </svg>
                        </button>
                        <div className="body">
                            <div className="c-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64.193" height="64.193" viewBox="0 0 64.193 64.193">
                                    <g id="check" transform="translate(-18.6 -18.418)">
                                        <path id="Subtraction_1" data-name="Subtraction 1" d="M30.595,61.193A30.6,30.6,0,0,1,18.687,2.4,30.6,30.6,0,0,1,42.5,58.789,30.4,30.4,0,0,1,30.595,61.193Zm0-55.1a24.514,24.514,0,0,0-9.538,47.09,24.514,24.514,0,1,0,9.538-47.09Z" transform="translate(20.1 19.918)" fill="#eed202" stroke="#fff" strokeWidth="3" />
                                        <line id="Line_234" data-name="Line 234" y2="23" transform="translate(51.196 35.361)" fill="none" stroke="#eed202" strokeLinecap="round" strokeWidth="3" />
                                        <line id="Line_235" data-name="Line 235" y2="0.041" transform="translate(51.196 65.32)" fill="none" stroke="#eed202" strokeLinecap="round" strokeWidth="4" />
                                    </g>
                                </svg>
                            </div>
                            <h4>{t('Logout_confirm_msg')}</h4>

                            <button type="button" onClick={this.props.logoutUser} className="btn btn-dark btn-logout">{t('Logout_btn_label')}</button>
                            <button type="button" onClick={this.props.closeLogoutPopup} className="btn btn-light btn-cancel">{t('Cancel_button')}</button>
                        </div>
                    </div>
                </div>
                <div className="popup-backdrop"></div>
            </>
        )
    }
}

export default (withTranslation()(PopUpLogout));